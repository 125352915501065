<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      hide-footer
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <table class="mt-2 mt-xl-0 w-100">
      <tr class="border-bottom">
        <th class="pb-50"><span class="font-weight-bold">İsim</span></th>
        <td class="pb-50">{{ previewData.name }}</td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Pozisyon</span></th>
        <td class="py-50">{{ previewData.positionName }}</td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Açıklama</span></th>
        <td class="py-50">{{ previewData.description }}</td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Durum</span></th>
        <td class="py-50">
          <active-column-table :data="previewData.isActive"></active-column-table>
        </td>
      </tr>
      <tr class="border-bottom">
        <th class="py-50"><span class="font-weight-bold">Oluşturma Zamanı</span></th>
        <td class="py-50">
          <date-time-column-table :data="previewData.createdOn"></date-time-column-table>
        </td>
      </tr>
      <tr>
        <th class="py-50"><span class="font-weight-bold">Değiştirme Zamanı</span></th>
        <td class="py-50">
          <date-time-column-table :data="previewData.modifiedOn"></date-time-column-table>
        </td>
      </tr>
    </table>

    <div class="border-bottom my-1"></div>

    <b-row class="d-flex justify-content-end align-items-center">
      <b-button
          class="mr-1"
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="closeModal">
        <feather-icon icon="XCircleIcon" size="16"/>
        <span class="align-middle" role="button"> Kapat</span>
      </b-button>
    </b-row>

    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BButton, BModal, BRow} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";

export default {
  components: {
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined) {
        busy.value = true
        store.dispatch('store/previewItem', {id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const closeModal = () => {
      emit('update:is-preview-modal', false)
      emit('update:data-record', {})
    }

    return {
      busy,
      previewData,

      closeModal,
      recordStatusFormat,
      recordStatusVariant
    }
  },
}
</script>
